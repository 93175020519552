

































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import {
  INQUIRIES_FOR_TALENT,
  ACCEPT_DECLINE
} from '@/store/inquiry/actions.ts'
import InquiryPageLayout from '@/views/layouts/InquiryPageLayout.vue'
import { InquiryDto } from '@/store/inquiry/types'
import { GenericRequest, SimpleServerResponse } from '@/store/types'
import Enums from '@/common/Enums'
import {
  ACCEPT, DECLINE
} from '@/common/inquiry'
import { SortingInfo } from '@/store/commonFormInput/types'
import { INQUIRE_COMPANY_CANCEL } from '@/store/student/actions'
@Component({
  components: { InquiryPageLayout }
})
export default class SentApplications extends Vue {
  @Action(INQUIRIES_FOR_TALENT)
  public getInquiries: (status: string) => Promise<InquiryDto[]>

  @Action(ACCEPT_DECLINE)
  public acceptOrDecline: (dto: GenericRequest) => Promise<SimpleServerResponse>

  @Action(INQUIRE_COMPANY_CANCEL)
  inquireCompanyCancel: (requestDto: GenericRequest) => Promise<SimpleServerResponse>

  private openInquiries: Array<InquiryDto> = [];
  private acceptedInquiries: Array<InquiryDto> = [];
  private declinedInquiries: Array<InquiryDto> = [];
  private isLoading = false
  private showError = false
  private errorMessage = ''
  private modalHeading = ''
  private modalExplanation = ''

  created (): void {
    this.loadData()
  }

  get sortable (): SortingInfo[] {
    return [
      { key: 'inquiryModifiedAt', label: this.$i18n.t('inquiries.request-date').toString() },
      { key: 'jobAdTitle', label: this.$i18n.t('job-ad.title').toString() },
      { key: 'companyName', label: this.$i18n.t('inquiries.company').toString() }
    ]
  }

  openConfirmationModal (): void {
    this.modalHeading = this.$t('inquiries.cancel-request-confirmation').toString()
    this.modalExplanation = this.$t('inquiries.cancel-request-text').toString()
  }

  cancelApplication (selectedInquiry: InquiryDto): void {
    const requestDto: GenericRequest = {
      query: [selectedInquiry.inquiryId as string],
      params: undefined
    }
    this.inquireCompanyCancel(requestDto).then(() => {
      this.openInquiries.splice(this.openInquiries.findIndex(inquiry => inquiry.inquiryId === selectedInquiry.inquiryId), 1)
    }).catch(error => {
      this.errorMessage = error
    })
  }

  showJobAd (selectedInquiry: InquiryDto): void {
    let interactionButtons: string[]
    switch (selectedInquiry.inquiryStatus) {
      case Enums.OPEN_INQUIRY_TO_TALENT: {
        interactionButtons = [ACCEPT, DECLINE]
        break
      }
      case Enums.INQUIRY_DENIED_BY_TALENT: {
        interactionButtons = [ACCEPT]
        break
      }
      case Enums.INQUIRY_ACCEPTED_BY_TALENT: {
        interactionButtons = [DECLINE]
        break
      }
      default: {
        interactionButtons = []
        break
      }
    }
    this.$router.push({
      name: 'sent-applications-job-ad',
      params: {
        id: selectedInquiry.jobAdId,
        isApplication: 'false',
        inquiryId: selectedInquiry.inquiryId as string,
        options: JSON.stringify(interactionButtons)
      }
    })
  }

  private loadData (): void {
    this.isLoading = true
    Promise.all([
      this.getInquiries(Enums.OPEN_INQUIRY_TO_COMPANY),
      this.getInquiries(Enums.INQUIRY_ACCEPTED_BY_COMPANY),
      this.getInquiries(Enums.INQUIRY_DENIED_BY_COMPANY)
    ]).then((data) => {
      this.openInquiries = data[0]
      this.acceptedInquiries = data[1]
      this.declinedInquiries = data[2]
    }).finally(() => {
      this.isLoading = false
    })
  }
}
