import { render, staticRenderFns } from "./SentApplications.vue?vue&type=template&id=6564c211&scoped=true&"
import script from "./SentApplications.vue?vue&type=script&lang=ts&"
export * from "./SentApplications.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6564c211",
  null
  
)

export default component.exports